<template>
  <div class="app sticky-footer" :class="{ '-is-article-page': isArticlePage }">
    <div class="wrapper">
      <SiteHeader class="header" />
      <NuxtPage class="main" />
    </div>
    <SiteFooter v-if="!isArticlePage" class="footer" data-footer />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';
import type { RouteLocationNormalized } from '#vue-router';
import { appPageTransition as defaultPageTransition } from '#build/nuxt.config.mjs';
import { translationInjectionKey } from '~/datocms/useInternalI18n';
import {
  type GlobalData,
  globalDataInjectionKey,
} from '~/datocms/useGlobalData';
import { initializeArticleContentHub } from '~/components/article/useArticleContentHub';

const i18n = useI18n();
const router = useRouter();
const route = useRoute();

const { data } = await useAsyncData(
  `global-data-${i18n.localeProperties.value.siteLocale}`,
  () =>
    $fetch('/api/content/global-data', {
      query: { locale: i18n.localeProperties.value.siteLocale },
      headers: {
        'Cache-Control': 'no-cache', // TODO: temporary solution to make caching system work
      },
    }),
);

const globalData = data as Ref<GlobalData>;

provide(globalDataInjectionKey, globalData);

provide(
  translationInjectionKey,
  (key) => globalData.value.translationPanel?.[key] ?? null,
);

initializeArticleContentHub();

const isArticlePage = computed(() => {
  return (route.name as string).startsWith('articles-slug');
});

// Close the site menu only after the transition is complete to prevent the flashing of the old page
// TODO: adjust this if there should be an page transition animation in the future
router.beforeEach((to, from) => {
  const nuxtApp = useNuxtApp();

  const hasTransition = (route: RouteLocationNormalized) =>
    !!(route.meta.pageTransition ?? defaultPageTransition);
  const hookToWait =
    hasTransition(from) && hasTransition(to)
      ? 'page:transition:finish'
      : 'page:finish';

  nuxtApp.hooks.hookOnce(hookToWait, async () => {
    await new Promise((resolve) => setTimeout(resolve, 0));
    closeSmallScreenNavigation();
  });

  function closeSmallScreenNavigation() {
    const els = document.querySelectorAll('.site-header-content');
    els.forEach((el) => {
      el.classList.remove('-is-open');
    });
  }
});

onMounted(() => {
  setFooterHeight();

  window.addEventListener('resize', setFooterHeight);
});

onUnmounted(() => {
  window.removeEventListener('resize', setFooterHeight);
});

function setFooterHeight() {
  const footerHeight =
    document.querySelector('[data-footer]')?.clientHeight ?? 0;
  document.documentElement.style.setProperty(
    '--footer-height',
    `${footerHeight}px`,
  );
}
</script>

<style scoped lang="scss">
.sticky-footer {
  display: grid;
  min-height: 100vh;

  @media (--vs) {
    grid-template-rows: 1fr auto;
    // ℹ️ We need the following line due to overflowing problems when child content is too wide
    grid-template-columns: minmax(0, auto);
  }

  @media (--vl) {
    &:not(:has(.end-of-page-backlink)) {
      padding-bottom: var(--footer-height, 100vh);
    }
  }

  > .wrapper {
    @media (--vs) {
      grid-row: 1 / 2;
      grid-column: 1 / -1;
      padding-inline: var(--fluid-outer-margin);
    }

    @media (--vl) {
      position: relative;
      padding: 0 var(--fluid-outer-margin) 2.5rem;
      background-color: var(--base-color-background);
      background-position: top center;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size: 100%;
      background-image: light-dark(
        none,
        url('/public/images/background-default-large-dark.svg')
      );
      z-index: 2;
    }

    &:has(main.-home) {
      @media (--vl) {
        background-image: light-dark(
          none,
          url('/public/images/background-home-large-dark.svg')
        );
      }
    }
  }

  > .footer {
    @media (--vs) {
      grid-row: 2 / -1;
      grid-column: 1 / -1;
      margin-top: 2rem;
    }

    @media (--vl) {
      position: fixed;
      bottom: 0;
      left: var(--fluid-outer-margin);
      right: var(--fluid-outer-margin);
      z-index: 1;

      &::before {
        display: block;
        content: '';
        position: fixed;
        left: 0;
        right: 0;
        height: 100%;
        background-color: var(--color-black);
        z-index: -1;
      }
    }
  }
}
</style>
